"use client";
import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import ListLink from "./ListLink";
import useScrollPosition from "../useScrollPosition/useScrollPosition";
import { navLinks } from "@/app/_utils/contants";
import Script from "next/script";

const MenuIcon = dynamic(() => import("../../../../assets/Icons/menuIcon.svg"), {
  ssr: false,
});
const CloseIcon = dynamic(() => import("../../../../assets/Icons/closeIcon.svg"), {
  ssr: false,
});

const Header = () => {
  const [menuContainer, setMenuContainer] = useState(false);
  const position = useScrollPosition();
  const menuRef = useRef(null);
  const [responsiveMenuIsActive, setResponsiveMenuIsActive] = useState(false);

  useEffect(() => {
    const handleOutClick = (e) => {
      if (!menuRef?.current?.contains(e.target)) {
        setMenuContainer(false);
      }
    };
    window.addEventListener("click", handleOutClick);
    return () => {
      window.removeEventListener("click", handleOutClick);
    };
  }, [menuRef]);

  const responsiveMenuHandler = (flag) => {
    setResponsiveMenuIsActive(flag);
  };

  return (
    <>
      <div id="header" className={position > 200 ? "background" : "headerComponent"}>
        <div className="left">
          <Link href="/">
            <img
              src="/Stage_Logo_White.png"
              priority={true}
              alt="Stage_logo"
              fill={true}
              className="stage-logo"
            />
          </Link>
          <ul className="links">
            <ListLink label="HOME" path="/" />
          </ul>
        </div>
        <div className="right">
          <ul className="links">
            {navLinks?.map((item) => (
              <ListLink
                key={item?.id}
                fullValue={item}
                label={item?.label}
                path={item?.path}
                subLink={item?.subLinks}
              />
            ))}
          </ul>
          <div className="menuIcon" onClick={() => responsiveMenuHandler(true)}>
            <MenuIcon />
          </div>
        </div>
        <div
          className={
            responsiveMenuIsActive
              ? "menuResponsiveContainer active"
              : "menuResponsiveContainer"
          }
        >
          <div className="closeIcon" onClick={() => responsiveMenuHandler(false)}>
            <CloseIcon />
          </div>
          <ul className="responsiveLinks" onClick={() => responsiveMenuHandler(false)}>
            {navLinks?.map((item) => (
              <ListLink
                key={item?.id}
                fullValue={item}
                label={item?.label}
                responsive={true}
                path={item?.path}
                subLink={item?.subLinks}
                setResponsiveMenuIsActive={setResponsiveMenuIsActive}
              />
            ))}
          </ul>
        </div>
        <Script id="convolo" strategy="afterInteractive">
          {` (function f() { var widget_key = 'ed541f9379471ea80b43ef20889e2750'; window.leadCM = { widget_key: widget_key, }; var em = document.createElement('script'); em.type = 'text/javascript'; em.async = true; em.src = 'https://app.convolo.ai/js/icallback.js?v=' + Math.random() + '&key=' + widget_key + '&uri=' + encodeURIComponent(window.location.href); var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(em, s); })();`}
        </Script>
      </div>
    </>
  );
};

export default Header;